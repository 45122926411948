/* A few from reset.css. There's more. */

.reception-outer img,
.reception-outer picture,
.reception-outer video,
.reception-outer canvas,
.reception-outer svg {
  display: block;
  max-width: 100%;
}

@media (min-width: 600px) {
  .body-reception {
    position: relative;
    /* height: 100vh; */
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .body-reception::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-image: url("/reception-wallpaper.png");
    background-repeat: repeat;
    background-size: 100px; */
    background-size: cover;
    background-position: center;
    opacity: 0.25;
    z-index: -1;
  }

  .bi-a.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_1.png");
  }

  .bi-b.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_2.png");
  }

  .bi-c.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_3.png");
  }

  .bi-d.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_4.png");
  }

  .bi-e.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_5.png");
  }

  .bi-f.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_6.png");
  }

  .bi-g.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_7.png");
  }

  .bi-h.body-reception::after {
    background-image: url("/backgrounds/sentiment_bg_8.png");
  }

  .reception-outer {
    max-width: clamp(560px, calc(100% - 128px), 800px);
  }
}

.reception-outer {
  margin: 0 auto;
  /* border: 8px solid hsl(44, 90%, 30%); */
  border: 8px solid hsl(44, 90%, 40%);
  padding: 60px;
  background: hsl(44, 100%, 95%);
  box-shadow: 0px 0px 8px 0px hsl(44deg 50% 20% / 35%);
}

.reception-inner {
  border: 4px solid hsl(44, 100%, 50%);
  padding: 40px;
  background: white;
}

#reception {
  min-height: 600px;
}

#reception .reception-a {
  color: hsl(44, 90%, 35%);
  font-weight: 600;
}

#reception .header-top {
  padding-bottom: 2px;
  font-size: 1.05rem;
}

#reception .header-bottom {
  padding-bottom: 32px;
  font-size: 1.05rem;
}

#reception .rsvp-link {
  margin: 24px auto 32px auto;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: hsl(44, 100%, 85%);
  width: 176px;
  cursor: pointer;
  display: block;
}

#reception .rsvp-link:hover {
  /* outline: 1px solid hsl(44, 100%, 50%); */
  background-color: hsl(44, 100%, 80%);
  text-decoration: none;
  box-shadow: 0px 0px 4px 0px hsl(44deg 100% 20% / 35%);
}

#reception .rsvp-link:active {
  outline: 1px solid hsl(44, 100%, 50%);
  background-color: hsl(44, 100%, 70%);
  text-decoration: none;
  /* box-shadow: 0px 0px 4px 0px hsl(44deg 100% 20% / 35%); */
}

.reception-inner h2 {
  margin-top: 0;
}

@media (max-width: 599px) {
  .body-reception {
    padding-top: 0;
  }

  .reception-outer {
    padding: 12px;
    border: none;
  }

  .reception-inner {
    padding: 24px;
  }
}

#reception .schedule-h3 {
  /* font-weight: 400;
  font-size: 1.1em;
  padding-bottom: 4px; */
  font-size: 1.3em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

#reception .subtitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

#reception .schedule-h3 {
  margin-top: 32px;
}

#reception .schedule-h3:first-of-type {
  margin-top: 24px;
}

#reception .accordian-container {
  display: flex;
}

#reception .accordian-container > div {
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid hsl(44, 100%, 50%);
  border-left: none;
  user-select: none;
}

#reception .accordian-container > div:first-of-type {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid hsl(44, 100%, 50%);
}

#reception .accordian-container > div:last-of-type {
  border-radius: 0 4px 4px 0;
}

#reception .icon {
  display: inline-block;
  margin-left: -2px;
  margin-top: -1px;
}

#reception .icon.icon-star {
  display: inline-block;
  margin-left: 3px;
  margin-right: -1px;
  margin-top: -3px;
}

#reception h2 {
  margin-bottom: 12px;
  font-size: 1.6em;
}

#reception .knee-container {
  margin-top: 24px;
}

#reception .knee-container > p {
  margin-bottom: 20px;
}

#reception .knee-container ul {
  list-style: circle;
  padding-left: 16px;
}

#reception .knee-container li {
  margin-top: 6px;
}

#reception hr {
  margin-top: 24px;
}

#reception label {
  margin-left: 8px;
}

#reception .toggle {
  cursor: pointer;
}

#reception .toggle:hover {
  text-decoration: underline;
}

#reception p {
  margin-bottom: 8px;
}

#reception.epilogue .scene-grid {
  display: grid;
  grid-template-columns: clamp(30px, calc(40% - 93px), 50px) 1fr;
  margin-bottom: 16px;
}
#reception.epilogue h2 {
  margin-top: 24px;
}
#reception.epilogue .scene-grid ~ h2 {
  /* all the h2 margin-top stuff is a hack */
  margin-top: 32px;
}

#reception.epilogue .scene-grid .scene-space {
  display: inline-block;
  width: 170px;
  max-width: calc(100% - 170px);
}

#reception.epilogue .scene-grid .scene-half-space {
  display: inline-block;
  width: 130px;
  max-width: calc(100% - 210px);
}
