.body-replacement {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  line-height: 1.45;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #333333;
  min-height: 100vh;
}

@media only screen and (min-width: 600px) {
  .body-replacement {
    background: hsl(44, 100%, 50%);
  }

  .outer-container {
    margin: 0 auto;
    max-width: 650px;
    padding-top: 2rem;
    xborder: 15px #333333 solid;
  }

  .container-fluid,
  .container {
    margin: 0 2rem;
    width: auto;
  }
}

@media only screen and (min-width: 1100px) {
  .body-replacement {
    background: hsl(44, 100%, 60%);
  }
}

@media only screen and (min-width: 1300px) {
  .body-replacement {
    background: hsl(44, 90%, 70%);
  }
  .container-fluid,
  .container {
    box-shadow: 0px 1px 3px 2px hsl(44, 50%, 70%);
  }
}

.container-fluid,
.container {
  background-color: #eeeeee;
  padding: 2rem;
}

.question {
  font-size: 0.75rem;
  border-radius: 5px;
  padding: 5px;
  background-color: hsl(44, 100%, 85%);
  margin-right: 4px;
  vertical-align: text-bottom;
}

.family-image {
  height: 80px;
  border-radius: 5px;
  margin-left: 2px;
  vertical-align: top;
}

.family-image2 {
  height: 80px;
  border-radius: 5px;
  margin-left: 2px;
  vertical-align: top;
}

.family-image:hover {
  opacity: 30%;
}

.family-image-container {
  position: relative;
}

.family-image-link {
  position: absolute;
  pointer-events: none;
  top: 22px;
  left: 40px;
}

.family-image-container .family-image-link {
  visibility: hidden;
}

.family-image-container:hover .family-image-link {
  visibility: visible;
}

.family-answers {
  width: 480px;
  padding-top: 10px;
}

.tablet-outer {
  padding: 10px;
  display: inline-block;
}

.tablet-inner {
  padding: 10px;
  background-color: #ffe7cf;
  width: 110px;
  height: 140px;
  border-radius: 6px;
  border: 1px solid black;
}

.tablet-act {
  padding: 5px;
  text-align: center;
}

.tablet-number {
  padding: 5px;
  text-align: center;
  font-size: 1.5rem;
}

.tablet-name {
  padding: 5px;
  text-align: center;
}

.lang-select-container {
  display: flex;
  justify-content: space-around;
}

.lang-select-outer {
  padding: 20px 10px 24px 10px;
}

.lang-select-inner {
  padding: 10px;
  background-color: #ffe7cf;
  width: 140px;
  xheight: 120px;
  border-radius: 6px;
  border: 1px solid black;
  cursor: pointer;
}

.lang-select-inner.lang-select-selected {
  background-color: #ffbb00;
}

.lang-select-name {
  text-align: center;
  font-size: 1.5rem;
}

.lang-select-subtitle {
  text-align: center;
  font-size: 1rem;
}

.family-select-outer {
  padding: 10px;
  display: inline-block;
}

.family-select-inner {
  padding: 10px;
  background-color: #ffe7cf;
  width: 140px;
  height: 110px;
  border-radius: 6px;
  border: 1px solid black;
}

.family-select-names {
  padding: 5px;
  text-align: center;
  font-size: 1.5rem;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  margin-top: 0.8rem;
  line-height: 1.3;
}

a {
  color: #333333;
  text-decoration: none;
}

a:hover {
  color: #333333;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Alegreya", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 2.1rem;
  line-height: 1.3;
  margin-bottom: 1rem;
  color: #111;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.7rem;
  margin-bottom: 1.2rem;
  color: #111;
}

h3 {
  font-size: 1.4rem;
  margin-top: 1.7rem;
  color: #333;
}

p {
  margin-bottom: 1rem;
}

.qblock {
  margin: 0.8rem 0 0.8rem 0;
}

.fa {
  text-align: center;
  color: #333;
}

.fa-file-text,
.fa-file-text-o {
  font-size: 0.92rem;
  position: relative;
  bottom: 1px;
}

.left-sidebar {
  margin-bottom: 1rem;
}

.left-sidebar .fa {
  width: 24px;
}

.light {
  color: #777777;
  display: inline;
}

.space {
  margin-top: 1.4rem;
}

.tiny {
  font-size: 1.05rem;
  color: black;
}

.center {
  text-align: center;
}

.inline {
  display: inline;
}

.pt1 {
  padding-top: 1rem;
}

.hide {
  opacity: 0;
}

.z2 {
  z-index: 2;
}

.bespoke-canvas {
  width: 100%;
  height: 550px;
  position: relative;
  margin-top: 20px;
}

.bespoke-instructions {
  font-size: 1rem;
  padding-top: 0px;
}

#bespoke-instructions-div {
  margin-bottom: 10px;
}

.bespoke-instructions ol {
  padding: 0px 0px 0px 0px;
  list-style-type: none;
}

.bespoke-instructions li {
  #padding: 5px 0px 5px 0px;
  margin-left: 10px;
  border-radius: 5px;
}

.bespoke-instructions .highlight {
  background-color: hsl(140, 80%, 80%);
}

.ingredient-box {
  text-align: center;
  width: 120px;
  /* height: 100%; */
  /* position: absolute; */
  background-color: #94a3b4;
  border-radius: 5px;
  padding-bottom: 15px;
}

.food-label {
  text-align: center;
  width: 120px;
  color: white;
  line-height: 1.2;
}

.leaf {
  width: 100px;
  filter: sepia(35%);
  margin: 20px 0px 10px 0px;
}

.leaf-bound {
  height: 70px;
}

.leaf.widen {
  width: 370px;
}

.idli-box {
  position: relative;
  height: 70px;
  width: 120px;
  margin-top: 10px;
}

.idli {
  width: 85px;
  position: absolute;
  top: 10px;
  left: 18px;
}

.idli.widen {
  width: 140px;
  filter: drop-shadow(2px 4px 2px #555555) brightness(1.05);
}

.cherry-box {
  position: relative;
  height: 45px;
  width: 120px;
  margin-top: 20px;
}

.cherry.widen {
  filter: drop-shadow(0px -1px 3px rgba(255, 255, 255, 0.5));
}

.cherry1 {
  width: 40px;
  left: 15px;
  margin-right: 5px;
}

.cherry2 {
  width: 32px;
  left: 65px;
}

.chocolate {
  width: 90px;
  margin: 20px 0px 12px 20px;
}

.hot-chocolate {
  width: 70%;
  filter: sepia(30%);
}

.cup {
  margin: 20px 0px 10px 22px;
  width: 70px;
  height: 90px;
}

.cup.widen {
  width: 100px;
}

.tumbler {
  width: 100%;
  #border: dashed 4px transparent;
  #transition: background-color 0.2s;
}

#milk {
  width: 75%;
  height: 30px;
  background-color: #ffffff;

  position: absolute;
  left: 10px;
  top: 10px;
  z-index: -1;
  border-radius: 20px;
}

#milk.brownen {
  background-color: #dfcec6;
}

.draggable {
  touch-action: none;
  user-select: none;
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.drop-active {
  /* border: 2px dotted #444; */
  /* border-radius: 5px; */
  filter: drop-shadow(0px 0px 6px hsl(120, 10%, 50%)) !important;
}

.drop-target {
  filter: drop-shadow(0px 0px 6px hsl(120, 10%, 30%)) !important;
}

.datamatch-form {
  color: #3388ff;
}

.datamatch-warning {
  width: 100%;
  padding: 8px;
  text-align: center;
  background-color: #ccc;
  margin-bottom: 16px;
  border-radius: 4px;
}

.tamil-character {
  display: inline-block;
  border: 1px black solid;
  padding: 5px;
  border-radius: 5px;
  font-size: 30px;
}

.character-box-outer {
  width: auto;
  display: inline-block;
  margin: 20px 20px 20px 20px;
}

.character-box-inner {
  background-color: #ffeebb;
  display: inline-block;
  border: 1px black solid;
  padding: 5px;
  border-radius: 5px;
}

.character-box-subtitle {
  font-style: italic;
  text-align: center;
}

.character {
  display: inline-block;
}

.character-box-contents {
  font-size: 20px;
}

.character-box-contents.big {
  font-size: 30px;
}

.character-box-contents.loose {
  line-height: 1.6;
  padding: 5px;
}

.a-as-in {
  background-color: #bbbbff;
}

.a-as-in-yoga {
  background-color: #77eebb;
}

.lang-step ul {
  list-style-type: disc;
  margin-left: 30px;
}

.ma {
  cursor: pointer;
}

.ma:hover {
}

.ma-selected {
  color: #3388ff;
  font-size: 30px;
}

.not-ma-selected {
  color: red;
  font-size: 30px;
}

.languages-apply-image {
  padding-top: 15px;
  padding-bottom: 15px;
}

.family-box-outer {
  width: auto;
  display: inline-block;
  margin: 20px 20px 20px 0px;
}

.family-box-inner {
  width: 100px;
  height: 150px;
  background-color: #ffeebb;
  display: inline-block;
  border: 1px black solid;
  padding: 5px;
  border-radius: 5px;
}

.family-box-subtitle {
  font-size: 0.95rem;
}

.dragf-boundary {
  position: relative;
}

.whole-family-container {
  position: absolute;
  top: 400px;
}

.whole-family {
  display: inline-block;
  padding: 5px;
}

.family-photos {
  padding-bottom: 500px;
}

.family-photos img {
  width: 100px;
  height: 150px;
  border-radius: 5px;
  margin: 5px;
}

.family-bottom {
  padding-top: 40px;
}

.age-select-container {
  position: relative;
  margin: auto;
}

.age-select {
  padding: 20px 0px 20px 0px;
  margin: auto;
}

.age-select select {
  width: 140px;
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 1rem;
  padding-left: 10px;
  border: none;
  appearance: menulist-button;
}

/* .age-select:after { */
/*     content: '>'; */
/*     font-size: 1.5rem; */
/*   color: #333; */
/*   -webkit-transform: rotate(90deg); */
/*   -moz-transform: rotate(90deg); */
/*   -ms-transform: rotate(90deg); */
/*   transform: rotate(90deg); */
/*   right: 11px; */
/*   /\*Adjust for position however you want*\/ */

/*   top: 18px; */
/*   padding: 0 0 2px; */
/*   /\*left line *\/ */

/*   position: absolute; */
/*   pointer-events: none; */
/* } */

.knee-done {
  padding-top: 20px;
}

.blue-link {
  color: #3388ff;
}

.online p a {
  color: #3388ff;
}

.pt20 {
  padding-top: 20px;
}

.pointer {
  cursor: pointer;
}

.inline {
  display: inline;
}
